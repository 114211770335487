// External Imports
import axios, { AxiosResponse } from "axios";

// Internal Imports
import { PatientUrl } from "./AUrl";
import { formsData } from "../../../LocalStorage/patients/interface";
import { getSessionID } from "../../../LocalStorage/gameSession/core";

/*
The payload for posting a form.

Note we might want to make this more generic in the future if we
add different types of forms.
 */
export interface PostFormPayload {
    turn: number;
    patient_id: number;
    form_type: string;
    discharge_destination: string;
    severity: string;
    patient_details: string;
    actions: string;
    situation: string;
}

/*
The interface for the response from the post form endpoint.
 */
export interface PostFormResponse {
    status: number;
    error?: string;
    data?: formsData;
}


export type PostFormType = (jwt: string, postFormPayload: PostFormPayload) => Promise<PostFormResponse>;

/*
The function to post a form for a patient.

@jwt: The JWT of the user requesting the post.
@postFormPayload: The payload containing the turn, patient ID, and form data.

@returns: The ApiResponse from the post form endpoint.
 */
export async function postForm(jwt: string, postFormPayload: PostFormPayload): Promise<PostFormResponse> {
    const url = new PatientUrl().postForm;
    let response: AxiosResponse<any>;
    if (!jwt) {
        return { status: 401, error: "JWT has expired" };
    }
    let session_id = getSessionID();
    try {
        response = await axios.post<formsData | string>(url, {
            session_id: session_id,
            turn: postFormPayload.turn,
            patient_id: postFormPayload.patient_id,
            form_type: postFormPayload.form_type,
            discharge_destination: postFormPayload.discharge_destination,
            severity: postFormPayload.severity,
            patient_details: postFormPayload.patient_details,
            actions: postFormPayload.actions,
            situation: postFormPayload.situation
        }, {
            headers: {
                'Content-Type': 'application/json',
                'token': jwt
            },
            validateStatus: () => true
        });
    } catch (error) {
        return {
            status: 500,
            error: 'Network or other error occurred'
        };
    }

    if (response.status === 201) {
        return {
            status: response.status,
            data: response.data as formsData
        };
    } else {
        return {
            status: response.status,
            error: response.data as string
        };
    }
}