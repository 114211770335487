// External imports
import React, { useEffect, useState } from "react";

// Internal imports
import MainPageLayout from "../../../components/core/header/MainPageLayout";
import WebsiteButton from "../../../components/core/button/WebsiteButton";
import ClassCreatedModal from "../../../components/core/modal/class-created-modal/ClassCreatedModal";
import NewClassFormDetails from "./NewClassFormComponent/NewClassDetails/NewClassFormDetails";
import { TrackerComponent } from "./TrackerComponent/TrackerComponent";
import { NewClassLeftPanel } from "./NewClassFormComponent/NewClassLeftPanel";
import { DiseaseChoicesCheckbox } from "./NewClassFormComponent/DiseaseChoicesComponent/DiseaseChoicesCheckbox/DiseaseChoicesCheckbox";
import {
    CreateClassPageWrap,
    CreateClassPageContent,
    LeftPanel,
    RightPanel,
    CreateClassHeaderRow,
    StyledHeader,
    StyledFlexGrow,
} from "./NewClassPage.styles";
import { CreateClassSchema } from "../../../core/ServerApiLayer/VOne/classes/create";
import { DiseaseStateController } from "../../../core/metaDataAccess/classes/diseaseChoices/stateController";
import { NewClassDataStore } from "../../../core/metaDataAccess/classes/store";
import { useForceUpdate } from "../../../core/metaDataAccess/utils";
import { getJwt } from "../../../core/LocalStorage/AuthSession/core";

// Translation imports
import { useLanguage } from "../../../core/translations/LanguageContext";
import { NewClassTranslations } from "./translations/types";
import { enTranslations } from "./translations/en";
import { deTranslations } from "./translations/de";
import { SupportedLanguage } from "../../../core/translations/supportedLanguages";

// Dependency injection imports
import { CreateClassType } from "../../../core/ServerApiLayer/VOne/classes/create";

// Constants
const TRANSLATIONS: Record<SupportedLanguage, NewClassTranslations> = {
    en: enTranslations,
    de: deTranslations,
};

/**
 * Props interface for the NewClassPage component
 * @interface NewClassPageProps
 * @property {CreateClassType} createApi - Function to create a new class via API
 */
export interface NewClassPageProps {
    createApi: CreateClassType;
}

/**
 * NewClassPage component
 * Allows teachers to create a new class with customizable settings and disease choices
 * @param {NewClassPageProps} props - Component props
 * @returns {React.ReactElement} Rendered component
 */
export const NewClassPage: React.FC<NewClassPageProps> = ({ createApi }) => {
    const { language } = useLanguage();
    const translations = TRANSLATIONS[language];

    const [DiseaseState, setDiseaseState] = useState(
        DiseaseStateController.getInstance()
    );
    const [showClassCreatedPopup, setShowClassCreatedPopup] = useState(false);
    const [authCode, setAuthCode] = useState("");
    const forceUpdate = useForceUpdate();

    const [name, setName] = useState("");
    const [patientCount, setPatientCount] = useState(3);
    const [classStartsOpen, setClassStartsOpen] = useState(false);
    const [patientArrivalInterval, setPatientArrivalInterval] = useState(20);

    /**
     * Subscribes to disease state updates
     */
    useEffect(() => {
        const updateDiseaseState = () => {
            setDiseaseState(DiseaseStateController.getInstance());
            forceUpdate();
        };
        const unsubscribe =
            DiseaseStateController.subscribe(updateDiseaseState);
        return () => unsubscribe();
    }, []);

    /**
     * Handles class creation by calling the API with form data
     */
    const doCreateClass = async (): Promise<void> => {
        const apiInterface: CreateClassSchema = {
            name: name,
            open: classStartsOpen,
            diseases: DiseaseState.getAllForApi(),
            starting_patient_no: patientCount,
            patient_arrival_interval: patientArrivalInterval,
            scoring_diagnosis: NewClassDataStore.getDiagnosisScore(),
            scoring_never: NewClassDataStore.getNeverScore(),
            scoring_protocol: NewClassDataStore.getProtocolScore(),
            images: NewClassDataStore.getEcgImagesEnabled(),
        };

        const jwt = getJwt();
        if (jwt === null) {
            alert(translations.errors.noJwt);
            return;
        }

        const response = await createApi(apiInterface, jwt);
        if (response.status === 201) {
            NewClassDataStore.scores.clear();
            setAuthCode(response.data?.auth_code ?? "");
            setShowClassCreatedPopup(true);
            resetClassForm();
            console.log(
                "Class created with auth code: " + response.data?.auth_code
            );
        } else {
            alert(translations.errors.createClass + response.error);
        }
    };

    /**
     * Handle reset form after class creation
     */
    const resetClassForm = (): void => {
        setName(""); // Reset class name
        setPatientCount(3); // Reset patient count
        setClassStartsOpen(false); // Reset class start open state
        setPatientArrivalInterval(20); // Reset patient arrival interval
        // Reset disease state to initial state
        DiseaseStateController.resetState();  // <-- Call to reset disease state
        setDiseaseState(DiseaseStateController.getInstance()); // Update the state in the component
    };


    /**
     * Handles closing the class created modal
     */
    const onRequestClose = (): void => {
        setShowClassCreatedPopup(false);
        setAuthCode("");
    };

    const isChosenDiseaseStateEmpty = DiseaseState.getAllChecked().length === 0;
    const canCreateClass =
        name.trim().length > 0 &&
        !isChosenDiseaseStateEmpty &&
        patientCount > 0;

    return (
        <MainPageLayout>
            {showClassCreatedPopup && authCode !== "" && (
                <ClassCreatedModal
                    onRequestClose={onRequestClose}
                    authCode={authCode}
                />
            )}
            <CreateClassPageWrap>
                <CreateClassHeaderRow>
                    <StyledHeader>{translations.header}</StyledHeader>
                    <StyledFlexGrow className="flex-grow" />
                    <DiseaseChoicesCheckbox
                        isGroup
                        label={translations.classStartsOpen}
                        checked={classStartsOpen}
                        onChangeChecked={setClassStartsOpen}
                    />
                    <div className="spacer" />
                    <WebsiteButton
                        onClick={doCreateClass}
                        disabled={!canCreateClass}
                        id="create-class-button"
                    >
                        {translations.createButton}
                    </WebsiteButton>
                </CreateClassHeaderRow>
                <NewClassFormDetails
                    handlePatientCountChange={(e) =>
                        setPatientCount(parseInt(e.target.value))
                    }
                    name={name}
                    setName={setName}
                    patientCount={patientCount}
                    patientArrivalInterval={patientArrivalInterval}
                    handlePatientArrivalIntervalChange={(e) =>
                        setPatientArrivalInterval(parseInt(e.target.value))
                    }
                />
                <CreateClassPageContent>
                    <LeftPanel>
                        <NewClassLeftPanel diseaseData={DiseaseState} />
                    </LeftPanel>
                    <RightPanel>
                        <TrackerComponent ChosenDiseaseState={DiseaseState} />
                    </RightPanel>
                </CreateClassPageContent>
            </CreateClassPageWrap>
        </MainPageLayout>
    );
};
