import React, { useState, ChangeEvent, FormEvent, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
// @ts-ignore
// @ts-ignore
import myGif from "../../../../assets/loading.gif";
// @ts-ignore
import successGif from "../../../../assets/tick.gif";
import { getUserRole } from "../../../../core/LocalStorage/AuthSession/core";
import { SupportedLanguage } from "../../../../core/translations/supportedLanguages";
import { LoginPageTranslations } from "../translations/types";
import { enTranslations } from "../translations/en";
import { deTranslations } from "../translations/de";
import { useLanguage } from "../../../../core/translations/LanguageContext";
import {
    ErrorText,
    ImageMarginBottomZero,
    jiggle,
    JiggleAnimation,
    MarginTop,
    OrSection,
    OrSectionLink,
    RegistrationForm,
    RegistrationJiggle,
    RegistrationLoading,
    RegistrationSuccess,
} from "./LoginFormComponent.styles";

export interface FormData {
    email: string;
    password: string;
    success: boolean;
    failure: boolean;
    error: string;
    loading: boolean;
}

export interface RegistrationFormProps {
    onSubmit: (data: FormData) => void;
    initialFormData?: FormData;
}

const TRANSLATIONS: Record<SupportedLanguage, LoginPageTranslations> = {
    en: enTranslations,
    de: deTranslations,
};

export const LoginForm: React.FC<RegistrationFormProps> = ({
    onSubmit,
    initialFormData,
}) => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState<FormData>(
        initialFormData || {
            email: "",
            password: "",
            success: false,
            failure: false,
            error: "",
            loading: false,
        }
    );
    const [redirecting, setRedirecting] = useState(false);

    const { language } = useLanguage();
    const translations = TRANSLATIONS[language];

    useEffect(() => {
        setFormData(initialFormData || formData);
    }, [initialFormData]);

    useEffect(() => {
        if (formData.success && !redirecting) {
            setRedirecting(true);
            const role = getUserRole();
            const redirectPath = role === "TEACHER" ? "/home" : "/start";

            // Small delay to show success message before redirect
            setTimeout(() => {
                navigate(redirectPath);
            }, 1500);
        }
    }, [formData.success, navigate]);

    const [formClass, setFormClass] = useState("");

    useEffect(() => {
        if (formData.error || formData.success) {
            setFormClass("JiggleAnimation");

            const timer = setTimeout(() => {
                setFormClass("");
            }, 500);

            return () => clearTimeout(timer);
        }
    }, [formData.error]);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (formData.email === "") {
            setFormData({
                ...formData,
                success: false,
                failure: true,
                error: translations.emailError,
            });
            return null;
        } else if (formData.password === "") {
            setFormData({
                ...formData,
                success: false,
                failure: true,
                error: translations.passwordError,
            });
            return null;
        }
        onSubmit(formData);
    };

    if (formData.loading) {
        return (
            <RegistrationLoading>
                <ImageMarginBottomZero
                    src={myGif}
                    alt="Loading animation"
                />
                <MarginTop>{translations.securityCheck}</MarginTop>
            </RegistrationLoading>
        );
    } else if (formData.success) {
        const role = getUserRole();
        return formClass === "JiggleAnimation" ? (
            <JiggleAnimation animation={jiggle}>
                <h2>{translations.loginSuccess}</h2>
                <img src={successGif} alt="Success animation" />
                <p>
                    {role === "teacher"
                        ? translations.redirectingTeacher
                        : translations.redirectingGame}
                </p>
            </JiggleAnimation>
        ) : (
            <RegistrationSuccess>
                <h2>{translations.loginSuccess}</h2>
                <img src={successGif} alt="Success animation" />
                <p>
                    {role === "teacher"
                        ? translations.redirectingTeacher
                        : translations.redirectingGame}
                </p>
            </RegistrationSuccess>
        );
    }

    return (
        formClass === "JiggleAnimation"?
        <div>
            <RegistrationJiggle onSubmit={handleSubmit} animation={jiggle}>
                <div>
                    <label>{translations.email}</label>
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        style={{ minWidth: "240px" }}
                    />
                </div>
                <div>
                    <label>{translations.password}</label>
                    <input
                        type="password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        style={{ minWidth: "240px" }}
                    />
                </div>
                {formData.failure && (
                    <ErrorText id={"ErrorMessage"}>{formData.error}</ErrorText>
                )}
                <button type="submit">{translations.loginButton}</button>
            </RegistrationJiggle>
            <OrSection>
                <span>{translations.or}</span>
                <OrSectionLink href="/register">
                    {translations.registerButton}
                </OrSectionLink>
            </OrSection>
        </div>:
         <div>
         <RegistrationForm onSubmit={handleSubmit}>
             <div>
                 <label>{translations.email}</label>
                 <input
                     type="email"
                     name="email"
                     value={formData.email}
                     onChange={handleChange}
                     style={{ minWidth: "240px" }}
                 />
             </div>
             <div>
                 <label>{translations.password}</label>
                 <input
                     type="password"
                     name="password"
                     value={formData.password}
                     onChange={handleChange}
                     style={{ minWidth: "240px" }}
                 />
             </div>
             {formData.failure && (
                 <ErrorText id={"ErrorMessage"}>{formData.error}</ErrorText>
             )}
             <button type="submit">{translations.loginButton}</button>
         </RegistrationForm>
         <OrSection>
             <span>{translations.or}</span>
             <OrSectionLink href="/register">
                 {translations.registerButton}
             </OrSectionLink>
         </OrSection>
     </div>
    );
};
