// External imports
import React from "react";

// Internal imports
import ScrubberInput from "../../../../../components/core/scrubber-input/ScrubberInput";
import { Flex, StyledInput } from "./NewClassFormDetails.styles";
import { NewClassFormLabelledField } from "./NewClassFormLabelledField";

// Translation imports
import { useLanguage } from '../../../../../core/translations/LanguageContext';
import { FormDetailsTranslations } from './translations/types';
import { enTranslations } from './translations/en';
import { deTranslations } from './translations/de';
import { SupportedLanguage } from '../../../../../core/translations/supportedLanguages';

// Constants
const TRANSLATIONS: Record<SupportedLanguage, FormDetailsTranslations> = {
    en: enTranslations,
    de: deTranslations,
};

const DEFAULT_PATIENT_COUNT = 3;
const DEFAULT_ARRIVAL_INTERVAL = 20;


interface FormDetailsProps {
    name: string;
    setName: (value: string) => void;
    patientCount: number;
    handlePatientCountChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    patientArrivalInterval: number;
    handlePatientArrivalIntervalChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const NewClassFormDetails: React.FC<FormDetailsProps> = ({
    name,
    setName,
    patientCount = DEFAULT_PATIENT_COUNT,
    handlePatientCountChange,
    patientArrivalInterval = DEFAULT_ARRIVAL_INTERVAL,
    handlePatientArrivalIntervalChange
}) => {
    const { language } = useLanguage();
    const translations = TRANSLATIONS[language];

    return (
        <Flex>
            <NewClassFormLabelledField label={translations.classNameLabel}>
                <StyledInput
                    type="text"
                    placeholder={translations.classNamePlaceholder}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    id="class-name-input"
                />
            </NewClassFormLabelledField>
            <NewClassFormLabelledField label={translations.patientCountLabel}>
                <StyledInput
                    type="number"
                    min={1}
                    max={100}
                    value={patientCount}
                    onChange={handlePatientCountChange}
                    id="patient-count-input"
                />
            </NewClassFormLabelledField>
            <NewClassFormLabelledField label={translations.arrivalIntervalLabel}>
                <StyledInput
                    type="number"
                    min={1}
                    max={100}
                    value={patientArrivalInterval}
                    onChange={handlePatientArrivalIntervalChange}
                    id="patient-arrival-interval"
                />
            </NewClassFormLabelledField>
        </Flex>
    );
};

export default NewClassFormDetails;