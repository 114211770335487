// src/pages/research/Research.config.ts

/**
 * Interface for paper translations
 */
interface PaperTranslations {
    title: string;
    description: string;
}

/**
 * Interface for research paper configuration
 */
interface ResearchPaper {
    id: string;
    status: 'published' | 'preprint' | 'inreview';
    doi: string;
    authors: string[];
    translations: {
        en: PaperTranslations;
        de: PaperTranslations;
    };
}

/**
 * Configuration data for research papers
 */
export const RESEARCH_PAPERS: ResearchPaper[] = [
    {
        id: 'emergency-game',
        status: 'published',
        doi: 'https://doi.org/10.1186/s12909-024-06056-z',
        authors: [
            "Alexandra Aster",
            "Christopher Hütt",
            "Caroline Morton",
            "Maxwell Flitton",
            "Matthias Carl Laupichler",
            "Tobias Raupach"
        ],
        translations: {
            en: {
                title: "Development and evaluation of an emergency department serious game for undergraduate medical students",
                description: "This paper describes the development and evaluation of a serious game designed to teach emergency department management to undergraduate medical students."
            },
            de: {
                title: "Entwicklung und Evaluierung eines Notaufnahme-Serious-Games für Medizinstudierende",
                description: "Diese Arbeit beschreibt die Entwicklung und Evaluierung eines Serious Games zur Vermittlung des Notaufnahmemanagements an Medizinstudierende."
            }
        }
    },
    // {
    //     id: 'emergency-game2',
    //     status: 'inreview',
    //     doi: 'https://doi.org/10.1186/s12909-024-06056-z',
    //     authors: [
    //         "Alexandra Aster",
    //         "Christopher Hütt",
    //         "Caroline Morton",
    //         "Maxwell Flitton",
    //         "Matthias Carl Laupichler",
    //         "Tobias Raupach"
    //     ],
    //     translations: {
    //         en: {
    //             title: "Development and evaluation of an emergency department serious game for undergraduate medical students",
    //             description: "This paper describes the development and evaluation of a serious game designed to teach emergency department management to undergraduate medical students."
    //         },
    //         de: {
    //             title: "Entwicklung und Evaluierung eines Notaufnahme-Serious-Games für Medizinstudierende",
    //             description: "Diese Arbeit beschreibt die Entwicklung und Evaluierung eines Serious Games zur Vermittlung des Notaufnahmemanagements an Medizinstudierende."
    //         }
    //     }
    // },
    // Add more papers here as needed
];