import React, { useEffect, useMemo } from "react";
import { createPortal } from "react-dom";
import { ModalDiv } from "./GlobalSmallPopup.styles";
import { OutsideClickHandler } from "./OutsideClickHandler";

const GlobalSmallPopup = ({
                              children,
                              onRequestClose,
                          }: {
    children: React.ReactNode;
    onRequestClose: () => void;
}) => {
    // Lazily ensure modal-root exists
    const modalRoot = useMemo(() => {
        let root = document.getElementById("modal-root");
        if (!root) {
            root = document.createElement("div");
            root.id = "modal-root";
            document.body.appendChild(root);
        }
        return root;
    }, []);

    // Create a new div for the popup content
    const el = useMemo(() => document.createElement("div"), []);

    useEffect(() => {
        modalRoot.appendChild(el);

        // Cleanup function to remove the element on unmount
        return () => {
            modalRoot.removeChild(el);
        };
    }, [el, modalRoot]);

    return createPortal(
        <ModalDiv>
            <div className="overlay">
                <OutsideClickHandler
                    onOutsideClick={() => {
                        onRequestClose();
                    }}
                >
                    <div className="content">{children}</div>
                </OutsideClickHandler>
            </div>
        </ModalDiv>,
        el
    );
};

export default GlobalSmallPopup;
